import React, { useState, useEffect } from "react"
import fetchJsonp from "fetch-jsonp"

require("es6-promise").polyfill()

const SmartFeedJobAlerts = props => {
  const [jaDetails, setJaDetails] = useState({
    CompanyGroupName: props.CompanyGroupName,
    EmailAddress: "",
    FirstName: "",
    LastName: "",
    keywords: "",
    geoLocation: "",
    Location: [],
    Region: [],
    Category: [],
    Industry: [],
    JobType: [],
    JobTime: [],
    MailFrequency: 86400000,
    acceptGDPR: false,
  })

  const [filterData, setFilterData] = useState({})
  const [locationData, setLocationData] = useState([])
  const [regionData, setRegionData] = useState([])
  const [categoryData, setCategoryData] = useState([])
  const [industryData, setIndustryData] = useState([])
  const [jobTypeData, setJobTypeData] = useState([])
  const [jobTimeData, setJobTimeData] = useState([])

  const [showGDPR, setShowGDPR] = useState(false)
  const [GDPRStatement, setGDPRStatement] = useState("")
  const [apiResponse, setApiResponse] = useState(false)

  let groupOrIdParam = "id"

  if (props.group) {
    groupOrIdParam = "group"
  }

  useEffect(() => {
    if (props.group) {
      if (props.location) {
        getApiData("location", "GetLocationListByGroup", setLocationData)
      }
      if (props.region) {
        getApiData("region", "GetRegionListByGroup", setRegionData)
      }
      if (props.category) {
        getApiData("category", "GetCategoryListByGroup", setCategoryData)
      }
      if (props.industry) {
        getApiData("industry", "GetIndustryListByGroup", setIndustryData)
      }
    } else {
      if (props.location) {
        getApiData("location", "GetAllLocationsListByCompany", setLocationData)
      }
      if (props.region) {
        getApiData("region", "GetAllRegionsListByCompany", setRegionData)
      }
      if (props.category) {
        getApiData("category", "GetAllCategoriesListByCompany", setCategoryData)
      }
      if (props.industry) {
        getApiData("industry", "GetAllIndustriesListByCompany", setIndustryData)
      }
    }

    getGDPRApiData()
  }, [])

  const changeGDPRVal = () => {
    let temp = { ...jaDetails }

    temp.acceptGDPR = !temp.acceptGDPR

    setJaDetails(temp)
  }

  const getApiData = (name, apiCall, setState) => {
    fetchJsonp(
      "https://sf.vacancy-filler.co.uk/CareerPage/" +
        apiCall +
        "?" +
        groupOrIdParam +
        "=" +
        props.apiKey
    )
      .then(function(response) {
        return response.json()
      })
      .then(function(jsonList) {
        let uniqueArray = []

        uniqueArray.push("All")

        jsonList.map((item, i) => {
          if (!uniqueArray.includes(item)) {
            uniqueArray.push(item)
          }
        })

        setState(uniqueArray)
      })
  }

  const getGDPRApiData = (name, apiCall, setState) => {
    let gdprID = props.apiKey

    if (props.group) {
      gdprID = props.gdprKey
    }

    fetchJsonp(
      "https://sf.vacancy-filler.co.uk/CareerPage/GetGdprStatementByCompanyId?encodedCompanyId=" +
        gdprID
    )
      .then(function(response) {
        return response.json()
      })
      .then(function(gdprStatement) {
        setGDPRStatement(gdprStatement)
      })
  }

  const subscribeToJobAlerts = () => {
    const serialize = function(obj) {
      var str = []
      for (var p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]))
        }
      return str.join("&")
    }

    let toPass = {
      [props.group ? "GroupId" : "CompanyId"]: props.apiKey,
      TimeZoneDifferenceMinutes: 0,
      CompanyGroupName: props.CompanyGroupName,
      MailFrequency: jaDetails.MailFrequency,
      EmailAddress: jaDetails.EmailAddress,
      FirstName: jaDetails.FirstName,
      LastName: jaDetails.LastName,
      ReturnToSearchURL: props.ReturnToSearchURL,
      VacancyPageBaseURL: props.VacancyPageBaseURL,
      UnsubscribeLink:
        "https://new-smart-feed.vacancy-filler.co.uk/unsubscribe.html?UnsubscribeID=",
      OptInLink:
        "https://new-smart-feed.vacancy-filler.co.uk/optin.html?OptInID=",
      PostingType: "careers",
      Location:
        jaDetails.Location[0] && jaDetails.Location[0] === "All"
          ? ""
          : jaDetails.Location.join("|"),
      Region:
        jaDetails.Region[0] && jaDetails.Region[0] === "All"
          ? ""
          : jaDetails.Region.join("|"),
      Industry:
        jaDetails.Industry[0] && jaDetails.Industry[0] === "All"
          ? ""
          : jaDetails.Industry.join("|"),
      Category:
        jaDetails.Category[0] && jaDetails.Category[0] === "All"
          ? ""
          : jaDetails.Category.join("|"),
      Distance: 15,
      Unit: "Miles",
    }

    fetchJsonp(
      `https://sf.vacancy-filler.co.uk/CareerPage/SubscribeForJobAlert?${groupOrIdParam}=` +
        props.apiKey +
        "&" +
        serialize(toPass)
    )
      .then(function(response) {
        return response.json()
      })
      .then(function(result) {
        setApiResponse(result)
      })
  }

  const updateField = (name, val) => {
    let temp = { ...jaDetails }

    temp[name] = val

    setJaDetails(temp)
  }

  const addFilter = (label, item) => {
    let temp = { ...jaDetails }

    if (temp[label].includes(item)) {
      temp[label].splice(temp[label].indexOf(item), 1)
    } else {
      if (item === "All") {
        temp[label] = ["All"]
      } else {
        temp[label].push(item)
      }
    }

    setJaDetails(temp)
  }

  const isItemChecked = (label, item) => {
    if (jaDetails[label].includes(item)) {
      return true
    } else {
      return false
    }
  }

  const renderApiData = (label, state) => {
    return (
      <div className="col-12 col-6-m ja-filters-check-wrap">
        <div className="ja-filters-check-inner-wrap">
          <div className="ja-filters-section-title">
            <label>{label}:</label>
          </div>
          {state &&
            Array.isArray(state) &&
            state.map((item, i) => (
              <div className="vf-alerts-check-wrap">
                <input
                  checked={isItemChecked(label, item)}
                  onChange={() => addFilter(label, item)}
                  type="checkbox"
                  className="vf-alerts-check"
                  name={label}
                  value={item}
                  disabled={jaDetails[label].includes("All") && item !== "All"}
                />
                <label className="vf-alerts-check-label" for={label}>
                  {" "}
                  {item}
                </label>
              </div>
            ))}
        </div>
      </div>
    )
  }

  return (
    <div className="VF_Alerts_Wrap">
      <div className="VF_Alerts_Title">
        Keep up to date with the latest jobs from us
      </div>

      <div className="VF_Alerts_Inner_Wrap">
        <div className="row">
          <div className="col-12 col-4-m">
            <input
              className="VF-form-control onchange-search form-control"
              onChange={e => updateField("EmailAddress", e.target.value)}
              placeholder={"Email Address"}
            ></input>
          </div>
          <div className="col-12 col-4-m">
            <input
              className="VF-form-control onchange-search form-control"
              onChange={e => updateField("FirstName", e.target.value)}
              placeholder={"First Name"}
            ></input>
          </div>
          <div className="col-12 col-4-m">
            <input
              className="VF-form-control onchange-search form-control"
              onChange={e => updateField("LastName", e.target.value)}
              placeholder={"Last Name"}
            ></input>
          </div>
        </div>
        <div className="row">
          {props.region && <>{renderApiData("Location", regionData)}</>}
          {props.category && <>{renderApiData("Category", categoryData)}</>}
        </div>
        <div>
          <input
            type="checkbox"
            name="gdpr"
            value="acceptGDPR"
            checked={jaDetails.acceptGDPR}
            onChange={() => changeGDPRVal()}
          />
          <label for="gdpr">
            {" "}
            I have read the{" "}
            <a onClick={e => setShowGDPR(!showGDPR)}>privacy statement</a> and
            agree to receive job alerts.
          </label>

          {showGDPR && (
            <div className="gdprStatement">
              <div
                dangerouslySetInnerHTML={{ __html: GDPRStatement.Statement }}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: GDPRStatement.GDPRCustomMessage,
                }}
              />
            </div>
          )}

          {GDPRStatement &&
          jaDetails.acceptGDPR === true &&
          jaDetails.EmailAddress !== "" &&
          jaDetails.FirstName !== "" &&
          jaDetails.LastName !== "" ? (
            <div className="subscrube-btn-wrap">
              <button
                className="btn btn-primary"
                onClick={() => subscribeToJobAlerts()}
              >
                Subscribe
              </button>
            </div>
          ) : (
            <div className="subscrube-btn-wrap">
              <button
                className="VF-btn ui secondary basic button fluid"
                disabled
              >
                Enter your details to subscribe
              </button>
            </div>
          )}
          {apiResponse === "OK" && (
            <p>You have successfully signed up to Job Alerts</p>
          )}
          {apiResponse === "Error" && (
            <p>There was an error, please try again</p>
          )}
          {apiResponse === "Updated" && (
            <p>You have successfully updated your Job Alerts subscription</p>
          )}
          {apiResponse === "Pending" && (
            <p>
              You have successfully signed up to Job Alerts, please check your
              email inbox to confirm your subscription.
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default SmartFeedJobAlerts
